import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';
import headerIMG from '../images/slide01.jpeg';
import Layout from '../components/layout';
import Seo from '../components/seo';

const products = ({ data }) => {
  const products = data.allItemsJson.nodes;
  const truncate = str => {
    return str.length > 10 ? str.substring(0, 120) + '...' : str;
  };

  const [filters, setFilters] = useState(products);
  const filterItem = catItem => {
    const updatedItems = products.filter(currentItem => {
      return currentItem.category == catItem;
    });
    setFilters(updatedItems);
  };

  return (
    <Layout>
      <Seo title="Our Products" />

      <header className="page-header" data-background={headerIMG} data-stellar-background-ratio="0.2">
        <div className="container">
          <h2>Our Products</h2>
          <p>One Stop shop for Indian Vegetables and spices.</p>
        </div>
        <div className="parallax-element" style={{ left: '-120px' }} data-stellar-ratio="2"></div>
      </header>

      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="button-group filters-button-group">
              <button className={filters.length === 33 ? 'button is-checked' : 'button'} onClick={() => setFilters(products)}>
                All
              </button>
              <button className={filters[0].category === 'Dals' ? 'button is-checked' : 'button'} onClick={() => filterItem('Dals')}>
                Dals {filterItem.category}
              </button>
              <button className={filters[0].category === 'Flours' ? 'button is-checked' : 'button'} onClick={() => filterItem('Flours')}>
                Flours
              </button>
              <button className={filters[0].category === 'Vegetables' ? 'button is-checked' : 'button'} onClick={() => filterItem('Vegetables')}>
                Vegetables
              </button>
              <button className={filters[1].category === 'Spices' ? 'button is-checked' : 'button'} onClick={() => filterItem('Spices')}>
                Spices
              </button>
            </div>
          </div>
          <motion.div layout className="row">
            <AnimatePresence>
              {filters.map(product => (
                <div className="col-md-4" key={product.id}>
                  <Link to={'/products/' + product.slug}>
                    <motion.div layout className="image-caption-box">
                      <figure>
                        <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.name} />
                      </figure>
                      <div className="content">
                        <h5>{product.name}</h5>
                        <p>{truncate(product.description)}</p>
                      </div>
                    </motion.div>
                  </Link>
                </div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
      </section>
    </Layout>
  );
};

export default products;

export const query = graphql`
  query ProductsPage {
    allItemsJson {
      nodes {
        id
        name
        slug
        category
        description
        image {
          childImageSharp {
            gatsbyImageData(width: 310, height: 310, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;
